<template>
  <div>
    <v-checkbox
      v-model="isEndTimeShown"
      :label="$t('preferences.showEndTime')"
      @change="updateIsEndTimeShown"
    />

    <v-row class="text-h6">
      <v-col>{{ $t('preferences.firstDayOfTheWeek') }} </v-col>
    </v-row>

    <v-radio-group
      v-model="firstDayOfTheWeek"
      row
      @change="updateFirstDayOfTheWeek"
    >
      <v-radio :label="$t('preferences.sunday')" value="sunday" />
      <v-radio :label="$t('preferences.monday')" value="monday" />
    </v-radio-group>

    <v-row class="text-h6">
      <v-col> {{ $t('preferences.defaultDepartmentOrTeam') }} </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="8" md="4">
        <DepartmentAndTeamSelector
          :is-null-department-shown="isNullDepartmentShown"
          @update-selected-group="updateDefaultGroup"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-divider />
      </v-col>
    </v-row>

    <v-row class="text-h6">
      <v-col> {{ $t('preferences.receiveNotificationTitle') }} </v-col>
    </v-row>

    <EventReminderSettingOption
      :is-enabled="isPushReminderEnabled"
      :label="$t('preferences.pushNotification')"
      :minutes-before-reminder="minutesBeforePushReminder"
      @update-is-enabled="updateIsPushReminderEnabled"
      @update-minutes-before-reminder="updateMinutesBeforePushReminder"
    />

    <v-row
      v-if="isPushReminderEnabled && isNotificationPermissionNotGranted"
      no-gutters
    >
      <v-col class="text-body-2 grey--text text--darken-1">
        <v-icon color="red"> mdi-alert </v-icon>
        {{ $t('preferences.browserBlocksNotifications') }}
      </v-col>
    </v-row>

    <EventReminderSettingOption
      :is-enabled="isEmailReminderEnabled"
      :label="$t('preferences.emailNotification')"
      :minutes-before-reminder="minutesBeforeEmailReminder"
      @update-is-enabled="updateIsEmailReminderEnabled"
      @update-minutes-before-reminder="updateMinutesBeforeEmailReminder"
    />

    <v-row>
      <v-col>
        <v-divider />
      </v-col>
    </v-row>

    <v-row class="text-h6">
      <v-col> {{ $t('preferences.otherNotifications') }} </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-checkbox
          v-model="isEventAndTaskEmailNotificationEnabled"
          :label="$t('preferences.sendEventAndTaskEmailsOnOthersCrud')"
          @change="updateIsEventAndTaskEmailNotificationEnabled"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-checkbox
          v-model="isMeetingInviteEmailNotificationEnabled"
          :label="$t('preferences.sendMeetingInviteEmails')"
          @change="updateIsMeetingInviteEmailNotificationEnabled"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import EventReminderSettingOption from './EventReminderSettingOption.vue';
import DepartmentAndTeamSelector from '../../../components/DepartmentAndTeamSelector.vue';

export default {
  components: {
    EventReminderSettingOption,
    DepartmentAndTeamSelector,
  },
  data() {
    return {
      isEndTimeShown: false,
      firstDayOfTheWeek: 'monday',
      isPushReminderEnabled: false,
      minutesBeforePushReminder: 10,
      isEmailReminderEnabled: false,
      minutesBeforeEmailReminder: 10,
      isEventAndTaskEmailNotificationEnabled: false,
      isMeetingInviteEmailNotificationEnabled: false,
      isNotificationPermissionNotGranted: false,
    };
  },
  computed: {
    ...mapGetters(['id', 'name', 'department', 'preferences', 'defaultGroup']),
    ...mapGetters('groups', [
      'selectedGroup',
      'getGroupByTypeAndId',
      'getLoggedInUserDepartment',
    ]),

    reminderType() {
      return this.isPushReminderEnabled ? 'push' : 'off';
    },

    isNullDepartmentShown() {
      return this.getLoggedInUserDepartment.id === null;
    },
  },

  watch: {
    preferences() {
      this.fetchAndAssignValuesFromPreferences();
    },
  },

  created() {
    this.assignDefaultValues();
    this.isNotificationPermissionNotGranted =
      this.getIsNotificationPermissionNotGranted();
  },

  methods: {
    assignDefaultValues() {
      this.fetchAndAssignValuesFromPreferences();
      this.$store.dispatch('groups/setSelectedGroup', this.defaultGroup);
    },

    fetchAndAssignValuesFromPreferences() {
      const preferences = { ...this.preferences };
      this.isEndTimeShown = preferences.is_end_time_shown;
      this.firstDayOfTheWeek = preferences.first_day_of_the_week;
      this.isPushReminderEnabled = preferences.reminder_type === 'push';
      this.minutesBeforePushReminder = preferences.notification_before_mins;
      this.isEmailReminderEnabled = preferences.is_email_reminder_enabled;
      this.minutesBeforeEmailReminder = preferences.email_before_mins;
      this.isEventAndTaskEmailNotificationEnabled =
        preferences.receive_event_and_task_emails;
      this.isMeetingInviteEmailNotificationEnabled =
        preferences.receive_meeting_invite_emails;
    },

    async updateIsEndTimeShown(isEndTimeShown) {
      await this.dispatchUpdateUserPreferences({
        is_end_time_shown: isEndTimeShown,
      });
    },

    async updateFirstDayOfTheWeek(day) {
      await this.dispatchUpdateUserPreferences({
        first_day_of_the_week: day,
      });
    },

    async updateDefaultGroup(groupType, groupId) {
      await this.dispatchUpdateUserPreferences({
        default_group_type: groupType,
        default_group_id: groupId,
      });
    },

    async updateIsPushReminderEnabled(isEnabled) {
      if (isEnabled && this.minutesBeforePushReminder === null) {
        this.minutesBeforePushReminder = 10;
      }
      this.isPushReminderEnabled = isEnabled;
      if (!isEnabled) {
        this.isNotificationPermissionNotGranted = false;
      }
      this.checkAndEnablePushReminder();
      await this.dispatchUpdateUserPreferences({
        reminder_type: this.reminderType,
        notification_before_mins: this.minutesBeforePushReminder,
      });
    },

    checkAndEnablePushReminder() {
      if (this.isPushReminderEnabled) {
        this.$root.$emit('enable-push-subscription');
      }
    },

    async updateMinutesBeforePushReminder(minutes) {
      this.minutesBeforePushReminder = minutes;
      await this.dispatchUpdateUserPreferences({
        reminder_type: this.reminderType,
        notification_before_mins: this.minutesBeforePushReminder,
      });
    },

    async updateIsEmailReminderEnabled(isEnabled) {
      if (isEnabled && this.minutesBeforeEmailReminder === null) {
        this.minutesBeforeEmailReminder = 10;
      }
      this.isEmailReminderEnabled = isEnabled;
      await this.dispatchUpdateUserPreferences({
        is_email_reminder_enabled: this.isEmailReminderEnabled,
        email_before_mins: this.minutesBeforeEmailReminder,
      });
    },

    async updateMinutesBeforeEmailReminder(minutes) {
      this.minutesBeforeEmailReminder = minutes;
      await this.dispatchUpdateUserPreferences({
        is_email_reminder_enabled: this.isEmailReminderEnabled,
        email_before_mins: this.minutesBeforeEmailReminder,
      });
    },

    async updateIsEventAndTaskEmailNotificationEnabled() {
      await this.dispatchUpdateUserPreferences({
        receive_event_and_task_emails:
          this.isEventAndTaskEmailNotificationEnabled,
      });
    },

    async updateIsMeetingInviteEmailNotificationEnabled() {
      await this.dispatchUpdateUserPreferences({
        receive_meeting_invite_emails:
          this.isMeetingInviteEmailNotificationEnabled,
      });
    },

    async dispatchUpdateUserPreferences(payload) {
      try {
        await this.$store.dispatch('updateUserPreferences', payload);
        this.$store.dispatch('openSnackbar', {
          color: 'success',
          message: this.$t('preferences.updated'),
        });
      } catch {
        this.$store.dispatch('openSnackbar', {
          color: 'error',
          message: this.$t('common.somethingWentWrong'),
        });
      }
    },

    getIsNotificationPermissionNotGranted() {
      if (!('Notification' in window)) {
        return false;
      }
      if (Notification.permission === 'granted') {
        return false;
      }
      return true;
    },
  },
};
</script>
