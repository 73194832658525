<template>
  <v-list class="pt-0">
    <v-card-title> {{ $t('search.selfResults') }} </v-card-title>
    <v-card-text>
      <v-progress-linear
        :indeterminate="selfPaginator.isLoading"
        :active="selfPaginator.isLoading"
        color="#0D47A1"
      />
      <v-row class="pr-3">
        <v-spacer></v-spacer>
        <v-col cols="10" sm="6" md="5" lg="4">
          <Navigation
            v-if="!$vuetify.breakpoint.mdAndUp"
            :paginator="selfPaginator"
            @fetch-prev="fetchPrevPageResults('self')"
            @fetch-next="fetchNextPageResults('self')"
          />
        </v-col>
      </v-row>
      <SchedulesTable
        :paginator="selfPaginator"
        @fetch-prev="fetchPrevPageResults('self')"
        @fetch-next="fetchNextPageResults('self')"
      />
    </v-card-text>
    <v-card-title> {{ $t('search.otherResults') }} </v-card-title>
    <v-card-text>
      <v-progress-linear
        :indeterminate="othersPaginator.isLoading"
        :active="othersPaginator.isLoading"
        color="#0D47A1"
      />
      <v-row class="pr-3">
        <v-spacer></v-spacer>
        <v-col cols="10" sm="6" md="5" lg="4">
          <Navigation
            v-if="!$vuetify.breakpoint.mdAndUp"
            :paginator="othersPaginator"
            @fetch-prev="fetchPrevPageResults()"
            @fetch-next="fetchNextPageResults()"
          />
        </v-col>
      </v-row>
      <SchedulesTable
        :paginator="othersPaginator"
        @fetch-prev="fetchPrevPageResults()"
        @fetch-next="fetchNextPageResults()"
      />
    </v-card-text>
    <AddEditEvent
      :disable-paste="true"
      @added:event="searchSchedules"
      @added:meeting="searchSchedules"
      @added:task="searchSchedules"
      @updated:event="searchSchedules"
    />
    <ViewScheduleModal
      ref="viewScheduleModal"
      @close="searchSchedules"
      @copy="copyToClipboard"
      @open-edit-form="openEditEvent"
    />
  </v-list>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ViewScheduleModal from '../../components/ViewScheduleModal.vue';
import AddEditEvent from '../../components/AddEditEvent.vue';
import SchedulesTable from './Components/SchedulesTable.vue';
import Navigation from './Components/NavigationAndPageDetails.vue';
import Paginator from '../../classes/paginator';

export default {
  components: {
    ViewScheduleModal,
    AddEditEvent,
    SchedulesTable,
    Navigation,
  },
  beforeRouteUpdate(to, from, next) {
    this.searchSchedules(to);
    next();
  },
  data() {
    return {
      selfPaginator: new Paginator(5),
      othersPaginator: new Paginator(15),
    };
  },
  computed: {
    ...mapGetters({
      eventTypes: 'normalizedEventTypes',
      nullEventType: 'defaultEventType',
      user: 'user',
      calendarOwner: 'calendarOwner',
      latestSearchAfter: 'latestSearchAfter',
      searchAfterArray: 'searchAfterArray',
      latestSearchAfterSelf: 'latestSearchAfterSelf',
      searchAfterArraySelf: 'searchAfterArraySelf',
    }),
  },

  mounted() {
    this.searchSchedules();
    this.$root.$on('update-user-calendar', () => this.searchSchedules());
  },

  methods: {
    ...mapActions(['setEventOnClipboard']),

    fetchPrevPageResults(type) {
      if (type === 'self') {
        this.selfPaginator.page -= 1;
        this.searchSchedulesSelf();
        return;
      }
      this.othersPaginator.page -= 1;
      this.searchSchedulesOthers();
    },

    fetchNextPageResults(type) {
      if (type === 'self') {
        this.selfPaginator.page += 1;
        this.searchSchedulesSelf();
        return;
      }
      this.othersPaginator.page += 1;
      this.searchSchedulesOthers();
    },

    searchSchedules(route = this.$route) {
      this.searchSchedulesSelf(route);
      this.searchSchedulesOthers(route);
    },

    async searchSchedulesSelf(route = this.$route) {
      if (!route.query.search) {
        return;
      }
      this.selfPaginator.isLoading = true;
      const response = await axios.get(`/api/search/schedules`, {
        params: {
          query: route.query.search,
          page: this.selfPaginator.page,
          perPage: this.selfPaginator.perPage,
          isSearchFromOwnCalendars: 1,
        },
      });
      this.selfPaginator = new Paginator(
        this.selfPaginator.perPage,
        false,
        response.data.total,
        response.data.page,
        response.data.lastPage,
        response.data.from,
        response.data.to,
        response.data.data,
      );
    },

    async searchSchedulesOthers(route = this.$route) {
      if (!route.query.search) {
        return;
      }
      this.othersPaginator.isLoading = true;
      const response = await axios.get(`/api/search/schedules`, {
        params: {
          query: route.query.search,
          page: this.othersPaginator.page,
          perPage: this.othersPaginator.perPage,
          isSearchFromOwnCalendars: 0,
        },
      });
      this.othersPaginator = new Paginator(
        this.othersPaginator.perPage,
        false,
        response.data.total,
        response.data.page,
        response.data.lastPage,
        response.data.from,
        response.data.to,
        response.data.data,
      );
    },

    copyToClipboard(schedule) {
      this.setEventOnClipboard(schedule);
    },

    openEditEvent() {
      const formExtras = {
        isEditing: true,
      };
      this.$store.dispatch('updateFormExtras', formExtras);
      this.$store.dispatch('openAddEditEventSheet');
    },
  },
};
</script>
