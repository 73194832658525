<template>
  <v-container>
    <v-row justify="center">
      <v-col md="6">
        <v-card>
          <v-card-text>
            <div v-if="seeAllReleases" v-html="sanitizedMessageHtml" />
            <div v-else v-html="sanitizedLatestReleaseHtml" />
          </v-card-text>
          <div class="text-center">
            <v-btn
              v-if="!seeAllReleases"
              text
              color="primary"
              @click="seeAllReleases = true"
            >
              {{ $t('releases.showAll') }}
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import colors from 'vuetify/es5/util/colors';
import xss from 'xss';
import { getReleases } from '../api/miscellaneous.api';

export default {
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.setTheme();
    });
  },
  data: () => ({
    releasesHtml: '',
    seeAllReleases: false,
  }),
  computed: {
    latestReleaseHtml() {
      const releaseIdentifier = '<h2';
      const latestReleaseEndIndex = this.releasesHtml
        .split(releaseIdentifier, 2)
        .join(releaseIdentifier).length;
      return this.releasesHtml.substr(0, latestReleaseEndIndex);
    },
    sanitizedMessageHtml() {
      return xss(this.releasesHtml);
    },
    sanitizedLatestReleaseHtml() {
      return xss(this.latestReleaseHtml);
    },
  },

  async mounted() {
    const response = await getReleases(this.$i18n.locale);
    this.releasesHtml = response.data;
  },

  methods: {
    setTheme() {
      this.$vuetify.theme.themes.light.primary = colors.blue.darken2;
      this.$vuetify.theme.themes.light.accent = colors.orange.base;
    },
  },
};
</script>
