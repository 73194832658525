<template>
  <v-row no-gutters justify="end">
    <v-col cols="8">
      <div v-if="!paginator.isLoading" :class="headerClass">
        {{ pageDetails }}
      </div>
      <div v-else :class="headerClass">
        {{ $t('search.fetching') }}
      </div>
    </v-col>
    <v-col cols="4">
      <v-btn
        text
        x-small
        :class="headerClass"
        :disabled="prevDisabled"
        @click="$emit('fetch-prev')"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn
        text
        x-small
        :class="headerClass"
        :disabled="nextDisabled"
        @click="$emit('fetch-next')"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import Paginator from '../../../classes/paginator';

export default {
  props: {
    paginator: {
      type: Paginator,
      required: true,
    },
  },
  data: () => ({
    headerClass: 'grey--text text--darken-4 caption',
  }),
  computed: {
    prevDisabled() {
      if (this.paginator.page === 1 || this.paginator.isLoading) {
        return true;
      }
      return false;
    },
    nextDisabled() {
      if (this.paginator.isLoading) {
        return true;
      }
      if (this.paginator.page === this.paginator.lastPage) {
        return true;
      }
      return false;
    },
    pageDetails() {
      return this.$t('search.searchResultsDisplay', {
        start: this.paginator.from,
        end: this.paginator.to,
        totalResults: this.paginator.total,
      });
    },
  },
};
</script>
