<template>
  <div>
    <v-row dense>
      <v-col>
        <v-checkbox
          :input-value="isEnabled"
          :label="label"
          hide-details
          @change="$emit('update-is-enabled', !isEnabled)"
        />
      </v-col>
    </v-row>

    <v-row v-if="isEnabled" dense align="end">
      <v-col cols="11">
        <v-text-field
          ref="textToCopy"
          :value="link"
          :placeholder="$t('preferences.linkToExportCalendar')"
          hide-details
          readonly
          append-icon="mdi-content-copy"
          @click:append="copyUrlToClipboard()"
        >
        </v-text-field>
      </v-col>
      <v-col cols="1">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn icon text rounded v-on="on" @click="$emit('refresh-url')">
              <v-icon> mdi-restart </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('preferences.regenerateURL') }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    isEnabled: {
      type: Boolean,
      default: false,
    },
    link: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
  },
  methods: {
    copyUrlToClipboard() {
      const textToCopy = this.$refs.textToCopy.$el.querySelector('input');
      textToCopy.select();
      document.execCommand('copy');
    },
  },
};
</script>
