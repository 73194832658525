export default class Paginator {
  constructor(
    perPage,
    isLoading = false,
    total = 0,
    page = 1,
    lastPage = 1,
    from = 0,
    to = 0,
    data = [],
  ) {
    this.perPage = perPage;
    this.isLoading = isLoading;
    this.total = total;
    this.page = page;
    this.lastPage = lastPage;
    this.from = from;
    this.to = to;
    this.data = data;
  }
}
