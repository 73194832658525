<template>
  <div>
    <v-row class="text-h6 mt-3">
      <v-col> {{ $t('preferences.sharingSettings') }} </v-col>
    </v-row>

    <CalendarSharingSettingOption
      :is-enabled="isPublicSharingEnabled"
      :link="publicCalendarUrl"
      :label="$t('preferences.enableNonPrivateSharing')"
      @update-is-enabled="updateIsPublicSharingEnabled"
      @refresh-url="regenerateUrlForPublicEvents"
    />

    <div style="height: 20px" />

    <CalendarSharingSettingOption
      :is-enabled="isPrivateSharingEnabled"
      :link="privateCalendarUrl"
      :label="$t('preferences.enablePrivateSharing')"
      @update-is-enabled="updateIsPrivateSharingEnabled"
      @refresh-url="regenerateUrlForPrivateEvents"
    />
  </div>
</template>

<script>
import CalendarSharingSettingOption from './CalendarSharingSettingOption.vue';
import {
  getCalendarSharingSettings,
  updatePublicCalendarSharingSetting,
  updatePrivateCalendarSharingSetting,
  getRegenaratedPublicCalendarSharingUrl,
  getRegenaratedPrivateCalendarSharingUrl,
} from '../../../api/userPreferences.api';

export default {
  components: { CalendarSharingSettingOption },
  data() {
    return {
      publicCalendarUrl: null,
      privateCalendarUrl: null,
      isPublicSharingEnabled: false,
      isPrivateSharingEnabled: false,
    };
  },
  created() {
    this.getShareCalendarStatus();
  },
  methods: {
    async getShareCalendarStatus() {
      const { data } = await getCalendarSharingSettings();

      if (data.public !== null) {
        this.isPublicSharingEnabled = true;
      }

      if (data.private !== null) {
        this.isPrivateSharingEnabled = true;
      }

      this.publicCalendarUrl = data.public;
      this.privateCalendarUrl = data.private;
    },

    async updateIsPublicSharingEnabled(isEnabled) {
      this.isPublicSharingEnabled = isEnabled;
      const { data } = await updatePublicCalendarSharingSetting(isEnabled);
      this.publicCalendarUrl = data;
    },

    async updateIsPrivateSharingEnabled(isEnabled) {
      this.isPrivateSharingEnabled = isEnabled;
      const { data } = await updatePrivateCalendarSharingSetting(isEnabled);
      this.privateCalendarUrl = data;
    },

    async regenerateUrlForPublicEvents() {
      if (this.isPublicSharingEnabled) {
        const { data } = await getRegenaratedPublicCalendarSharingUrl();
        this.publicCalendarUrl = data;
      }
    },

    async regenerateUrlForPrivateEvents() {
      if (this.isPrivateSharingEnabled) {
        const { data } = await getRegenaratedPrivateCalendarSharingUrl();
        this.privateCalendarUrl = data;
      }
    },
  },
};
</script>
