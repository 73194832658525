<template>
  <v-container>
    <v-row class="text-h5 mb-6">
      <v-col>
        {{ $t('preferences.title') }}
      </v-col>
    </v-row>
    <PreferencesSettings />
    <v-row>
      <v-col>
        <v-divider />
      </v-col>
    </v-row>
    <CalendarSharingSettings />
  </v-container>
</template>

<script>
import PreferencesSettings from './components/PreferencesSettings.vue';
import CalendarSharingSettings from './components/CalendarSharingSettings.vue';

export default {
  components: {
    PreferencesSettings,
    CalendarSharingSettings,
  },
};
</script>
