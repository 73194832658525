<template>
  <v-data-table
    :headers="headers"
    :items="paginator.data"
    :items-per-page="paginator.perPage"
    class="elevation-1"
    :mobile-breakpoint="0"
    hide-default-footer
    :no-data-text="noDataText"
    @click:row="openScheduleDetails"
  >
    <template #[`header.subject`]="{ header }">
      <v-row class="pa-0 ma-0" no-gutters>
        <v-col md="4" lg="6">
          {{ header.text }}
        </v-col>

        <v-col md="8" lg="6">
          <Navigation
            v-if="$vuetify.breakpoint.mdAndUp"
            :paginator="paginator"
            @fetch-prev="$emit('fetch-prev')"
            @fetch-next="$emit('fetch-next')"
          />
        </v-col>
      </v-row>
    </template>
    <template #[`item.start`]="{ item }">
      <v-btn class="pa-0 ma-0" text @click="navigateToDate(item)">
        {{ $d(App.helpers.getDateObject(item.start), 'allDayEvent') }}
      </v-btn>
    </template>
    <template #[`item.all_day`]="{ item }">
      {{ item.all_day ? $t('event.all_day') : getScheduleTime(item) }}
    </template>
    <template #[`item.event_type_id`]="{ item }">
      <v-chip :color="getScheduleTypeColor(item)" label>
        {{ getScheduleType(item) }}
      </v-chip>
    </template>
    <template #[`item.owner`]="{ item }">
      {{ item.owner.name }}
    </template>
    <template #[`item.subject`]="{ item }">
      {{ item.subject }}
    </template>
    <template #footer="{}">
      <v-row class="pr-3">
        <v-spacer></v-spacer>
        <v-col cols="10" sm="6" md="5" lg="4">
          <Navigation
            :paginator="paginator"
            @fetch-prev="$emit('fetch-prev')"
            @fetch-next="$emit('fetch-next')"
          />
        </v-col>
      </v-row>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from 'vuex';
import Navigation from './NavigationAndPageDetails.vue';
import Paginator from '../../../classes/paginator';

export default {
  components: {
    Navigation,
  },
  props: {
    paginator: {
      type: Paginator,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      eventTypes: 'normalizedEventTypes',
      nullEventType: 'defaultEventType',
      user: 'user',
      calendarOwner: 'calendarOwner',
    }),
    noDataText() {
      if (this.paginator.isLoading) {
        return this.$t('common.loadingResults');
      }
      return this.$t('common.noMatchingResults');
    },
    headers: () => [
      {
        text: 'Start Date',
        value: 'start',
        width: '10%',
        sortable: false,
      },
      {
        text: 'Start Time',
        value: 'all_day',
        width: '15%',
        sortable: false,
      },
      {
        text: 'Event type',
        value: 'event_type_id',
        width: '10%',
        sortable: false,
      },
      {
        text: 'Calendar',
        value: 'owner',
        width: '15%',
        sortable: false,
      },
      { text: 'Subject', value: 'subject', width: '50%', sortable: false },
    ],
  },
  methods: {
    getScheduleTime(schedule) {
      const startTime = this.$d(
        App.helpers.getDateObject(schedule.start),
        'timeOnly',
      );
      const endTime = this.$d(
        App.helpers.getDateObject(schedule.end),
        'timeOnly',
      );
      return `${startTime} - ${endTime}`;
    },
    getScheduleTypeColor(schedule) {
      let eventType = this.nullEventType;
      if (schedule.event_type_id) {
        eventType = this.eventTypes[schedule.event_type_id];
      }
      switch (schedule.type) {
        case 'task':
          return 'green';
        default:
          return eventType.color;
      }
    },
    getScheduleType(schedule) {
      let eventType = this.nullEventType;
      if (schedule.event_type_id) {
        eventType = this.eventTypes[schedule.event_type_id];
      }
      switch (schedule.type) {
        case 'task':
          return this.$t('common.task');
        default:
          return eventType.name;
      }
    },

    openScheduleDetails(schedule, itemMeta) {
      this.$store.dispatch('resetAddEditEventFormState');
      this.$store.dispatch('updateEvent', schedule);
      const user = this.getUserWithCalendarsForSchedule(schedule);
      const calendarOptions = this.filterCalendarsBasedOnPermission(
        user.calendars,
      );
      const formExtras = {
        calendars: calendarOptions,
        formDates: {
          start: schedule.start,
          end: schedule.end,
        },
        owner: {
          id: schedule.owner.id,
          type: schedule.owner.type,
        },
        defaultCalendar: schedule.calendar_id,
      };
      this.$store.dispatch('updateFormExtras', { ...formExtras });
      const menuPosition = {
        x: window.event.clientX,
        y: window.event.clientY,
      };
      this.$store.dispatch('updateEventMenuPosition', { ...menuPosition });
      setTimeout(() => {
        this.$store.dispatch('openViewScheduleDetailsMenu');
      }, 10);
    },

    getUserWithCalendarsForSchedule(schedule) {
      return this.calendarOwner(schedule.owner.id, schedule.owner.type);
    },

    filterCalendarsBasedOnPermission(calendars) {
      return calendars.filter((calendar) => {
        if (calendar.user_id == this.user.id) return true;
        if (
          calendar.my_department === 'private' &&
          calendar.other_department === 'private'
        ) {
          return false;
        }
        return true;
      });
    },

    navigateToDate(item) {
      window.event.stopPropagation();
      const calendarOwner = this.calendarOwner(item.owner.id, item.owner.type);
      this.$store.dispatch('setCurrentCalendarOwner', calendarOwner);
      const date = App.helpers.getISODateString(item.start);
      this.$store.dispatch('setCurrentDate', date);
      this.$router.push({
        name: 'day',
      });
    },
  },
};
</script>
