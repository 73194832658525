<template>
  <div>
    <v-row dense>
      <v-col cols="4">
        <v-checkbox
          :input-value="isEnabled"
          :label="label"
          @change="$emit('update-is-enabled', !isEnabled)"
        />
      </v-col>
      <v-col v-if="isEnabled" cols="4">
        <v-text-field
          :label="$t('preferences.timeInputLabel')"
          :value="minutesBeforeReminder"
          :suffix="$t('preferences.minutes')"
          type="number"
          persistent-placeholder
          @change="emitMinutesBeforeReminder"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    isEnabled: {
      type: Boolean,
      default: false,
    },
    minutesBeforeReminder: {
      type: [Number, String],
      default: 10,
    },
    label: {
      type: String,
      default: '',
    },
  },
  methods: {
    emitMinutesBeforeReminder(value) {
      if (value === null || value === '') {
        value = 0;
      }
      this.$emit('update-minutes-before-reminder', value);
    },
  },
};
</script>
